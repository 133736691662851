import { renderWithTrackingContext } from 'common/components/utils';
import Dynamic from 'common/components/dynamic';
import { getPageName } from 'common/bc.core';
// TODO jcordero delete componentGroupWrapSelector parameter when brand-home
// is migrated to use components-client-group with js-client-group-component selector

// TODO check if "wrapperClass" can be removed, seems redundant in most cases
function loadComponentGroup(componentGroupData, wrapperClass, modifierClass, componentGroupWrapSelector = '.js-client-group-component') {
  const pageName = getPageName();

  renderWithTrackingContext(
    Dynamic,
    document.querySelector(componentGroupWrapSelector),
    {
      componentGroupData,
      modifierClass,
      wrapperClass,
    },
    {
      pageName,
      linkNameComponent: pageName,
    },
  );
}

export default loadComponentGroup;
