// Facebook Like Button code (from developers.facebook.com/docs/reference/plugins/like)

window.fbAsyncInit = function() {
  FB.init({
    appId: 307556929258838,
    xfbml: true,
  });
};

define(['common/main'], function() {
  if (!has('medium')) {
    return;
  }
  var load = function() {
    if (document.getElementById('facebook-jssdk')) {
      return;
    }
    var js = document.createElement('script');
    var fjs = document.getElementsByTagName('script')[0];

    js.id = 'facebook-jssdk';
    js.async = true;
    js.src = '//connect.facebook.net/en_US/all.js';
    fjs.parentNode.insertBefore(js, fjs);
  };

  if (has('socialbuttonsonload')) {
    if (window.addEventListener) {
      window.addEventListener('load', load, false);
    } else if (window.attachEvent) {
      window.attachEvent('onload', load);
    }
  } else {
    load();
  }
});
