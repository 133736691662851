define(function(require) {
  require('common/widgets/bc.lazyloader');
  require('common/widgets/bc.slideshow');
  require('common/includes/facebook.like');
  require('jquery-ui/tabs');
  require('vendors/jquery.hammer');
  var BaseController = require('common/controllers/base');
  var { default: loadComponentGroup } = require('common/components/dynamic/bootstrap');
  var { each } = require('underscore');
  var { focusFirstErrorField } = require('common/util/form-validation-helper');

  // TODO can this be separated into what each site specifically need?
  // SAC and CC have a huge unnecessary overload on assets size due to unused react/components being bundled
  var StaticContentController = BaseController.extend({
    _selectors: {
      body: '.body',
      looks: '.js-stl',
    },

    initialize() {
      var self = this;

      $.subscribe('load.slideshow', self._initSlideshow, self);

      // Custom event to hook onto from BCC injected scripts
      $(document).trigger('static-content-page-init');

      $.subscribe('client-group.domReady', self._initComponentGroup, self);
      BC.sub('client-group.domReady', self._initComponentGroup, self);
    },

    ready() {
      var self = this;

      $('.js-launch-template-lazyload').lazyloader({
        auto: true,
        proximity: 200,
      });

      if ($('.sticker-form-wrapper').length) {
        focusFirstErrorField('alert-inline input');
      }

      self.event = has('mobile') ? 'tap' : 'click';
      self.$promoResponsive = $('.promo-content-responsive');
      if (self.$promoResponsive.length) {
        // Dark promos
        if ($('.promo-dark').length) {
          $('.page').addClass('page-dark');
        }
        // Init tabs
        var $tabsCont = $('.promo-content-responsive .ui-tabs');

        $tabsCont.tabs();

        // Handle product item events
        self._handleProductItems();

        // Back to top functionality
        self._backToTop();

        // Viewer functionality
        self._handleViewer();

        // Slideshow functionality
        self._initSlideshow();
      }

      // Full width responsive content
      self.$promoFluid = $('.promo-fluid');
      if (self.$promoFluid.length) {
        $('.page .body').addClass('body-fluid');
        $('.page .main').addClass('main-fluid');
      }
      self._elements.body.lazyloader({ auto: true, proximity: 200 });

      self._initShopTheLook();

      var $giftChoices = $('.js-gift-choices');

      if ($giftChoices.length) {
        $giftChoices.closest('.body').css('max-width', 'inherit');
      }
    },

    _backToTop() {
      $('.js-back-to-top').hammer()
        .on(this.event, function() {
          $('html, body').animate({
            scrollTop: 0,
          }, 500);
        });
    },

    _handleProductItems() {
      var self = this;

      self.$promoResponsive.find('.product-item').hammer()
        .on(self.event, function() {
          var $prodItem = $(this);

          $prodItem.parents('ul').find('a')
            .removeClass('active');
          $prodItem.addClass('active');
        });
    },

    _handleViewer() {
      var self = this;
      var $viewer = $('.js-viewer-panel');
      var $viewerContent = $viewer.find('.js-viewer-content');
      var $viewerLinks = self.$promoResponsive.find('.js-viewer-link');

      if ($viewerContent.length) {
        // Hide all content and show the first one.
        $viewerContent.hide();
        $viewerContent.filter(':first-child').show();

        // Activate first link
        $viewerLinks.removeClass('active');
        $($viewerLinks.get(0)).addClass('active');

        // Bind event
        $('.js-viewer-link').hammer()
          .on(self.event, function(event) {
            var $this = $(this);

            event.preventDefault();

            $viewer.find('.js-viewer-content').hide();
            $viewer.find($this.attr('href')).show();

            $('html, body').animate({
              scrollTop: $viewer.offset().top - $('.head').outerHeight(),
            }, 500);
          });
      }
    },

    _initComponentGroup(componentGroupData) {
      loadComponentGroup(componentGroupData, 'component-group-wrap', componentGroupData.modifierClass, componentGroupData.wrapClass);
    },

    _initShopTheLook() {
      var self = this;
      var $looks = self._elements.looks;

      if ($looks && $looks.length) {
        if (typeof require.ensure !== 'function') {
          require('common/util/ensure-shim')(require);
        }

        require.ensure(['common/widgets/bc.shopthelook'], function(require) {
          require('common/widgets/bc.shopthelook');

          $looks.shopthelook();
        }, 'shop.the.look');
      }
    },

    // Initializes slideshow.
    _initSlideshow() {
      var self = this;
      var $slideshows = self.$promoResponsive.find('.ui-slideshow');

      // Init every slideshow on the page.
      if ($slideshows.length) {
        each($slideshows, function(slideshow) {
          var $slideshow = $(slideshow);
          var $slides = $slideshow.find('li');
          var thumbs = [];

          // Substitute the large slide images
          // for smaller ones for mobile.
          if (has('mobile')) {
            each($slides, function(slide) {
              var $slideItem = $(slide);
              var mobileSlide = $slideItem.data('background-image-small');

              if (mobileSlide) {
                $slideItem.attr('data-background-image', mobileSlide);
              }
            });
          }

          // Use thumbnails instead of icons for the slideshow controller.
          each($slides.filter('[data-thumb]'), function(slide) {
            thumbs.push($(slide).data('thumb'));
          });

          $slideshow.slideshow({
            animateDuration: 300,
            controllerOptions: {
              showThumbnails: thumbs.length > 0,
              thumbnails: thumbs,
            },
          });
        });
      }
    },
  });

  return StaticContentController;
});
