// Slideshow
// ===================
define(function(require) {
  var { bind, delay, shuffle } = require('underscore');

  require('common/widgets/bc.controller');
  require('common/widgets/bc.lazyloader');
  require('jquery-ui/widget');
  require('vendors/jquery.hammer');

  $.widget('bc.slideshow', {
    options: {
      animateDuration: 1200,
      containerSel: '.ui-slideshow-container',
      controllerOptions: {
        controlIndex: false,
        imageLarge: false,
        imageMedium: false,
        imagesLarge: [],
        imagesMedium: [],
        imageTitle: false,
        imageTitles: [],
        pauseControl: false,
        showThumbnails: false,
        thumbnails: [],
        useMobileGestures: true,
      },
      currentClass: 'current',
      easing: 'swing',
      fullViewport: false,
      loaderOptions: { ajaxloader: { position: 'center', size: 'large', style: 'dark' } },
      randomize: true,
      randomizeSel: '.js-slideshow-randomslide',
      resize: false,
      startPaused: true,
      type: 'fade',
      waitDuration: 7000,

      // new parameters
      carouselThumbsNum: 6,
      loopAtEnd: true,
      prevBtn: '',
      nextBtn: '',
    },

    // Create controller element, setup the ajax loader.
    _create() {
      var self = this;
      var options = self.options;
      var $element = self.element;
      var $container = $element.find(options.containerSel);
      var $randomSlides;
      var $slides;

      $element.addClass('ui-slideshow-' + options.type);

      if (options.fullViewport) {
        $element.addClass('ui-slideshow--full-viewport');
      }

      self._$container = $container = $container.length ? $container : $element;
      self._paused = false,
      // Scrolling the control items
      self._controllerPage = 1,
      self._controllerPageFinal = 0,
      self._fromFinal = false;

      // Randomize slides that don't have a priority value
      if (options.randomize) {
        $randomSlides = $container.children(options.randomizeSel);
        if ($randomSlides.length > 1) {
          $randomSlides.remove();
          $container.append(shuffle($randomSlides));
        }
      }
      self._$slides = $slides = $container.children('li');

      // The mechanics of the slideshow (`fade`, `push` or another in the future).
      $.extend(self, new $.bc.slideshow[options.type](self));

      // Initialize controller.
      $element.controller($.extend(options.controllerOptions, {
        controlIndex: options.controlIndex,
        imageTitle: options.imageTitle,
        imageTitles: options.imageTitles,
        imageMedium: options.imageMedium,
        imagesMedium: options.mediumsList,
        imageLarge: options.imageLarge,
        imagesLarge: options.largesList,
        showThumbnails: options.showThumbnails,
        thumbnails: options.thumbnails,
        tracks: $slides.length,
      }));

      // Bind to navigation events.
      self._on({
        controllerclickpause: 'pause',
        controllerclicknext: 'next',
        controllerclicktrack: 'go',
        controllerclickprev: 'prev',
      });

      if (options.controllerOptions.useMobileGestures) {
        self.element
          .hammer().on('swipeleft', bind(self.next, self))
          .hammer()
          .on('swiperight', bind(self.prev, self));
      }

      if (options.resize) {
        self._on(window, { resize: '_resize' });
        self._resize();
      }

      if (options.startPaused) {
        self.pause();
      }

      self._trigger('afterCreate');

      // Start the show.
      self.go(0);
    },

    // Go to a specific slide in the show specified by a numeric `index`.
    go(event, index) {
      var self = this;
      var options = self.options;
      var length = self._$slides.length;
      var slide;

      self._forward = true;

      if (arguments.length === 1) {
        index = event;
      }

      if (options.loopAtEnd) {
        index = (length + index) % length;
      }

      if (self._currentIndex === index) {
        return;
      }

      // after we defined index we can check
      // if we going forward of backward
      if (index < self._currentIndex) {
        self._forward = false;
      }

      clearTimeout(self._timeout);

      if (options.loopAtEnd) {
        self._currentIndex = index;
      } else if (index < length) {
        self._currentIndex = index;
      }
      self._currentIndex = index;

      // Update navigation element.
      if (options.loopAtEnd) {
        self.element.controller('option', 'index', index);
        slide = self._$slides.get(index);
      } else if (index < length) {
        self.element.controller('option', 'index', index);
        slide = self._$slides.get(index);
      }

      $(slide)
        .lazyloader($.extend(options.loaderOptions, {
          error: bind(self._error, self, slide),
          finish: bind(self._load, self, slide),
        }))
        .lazyloader('load');

      // showing and displaying nav arrow
      if (!options.loopAtEnd) {
        if (index > 0) {
          $(options.prevBtn).show();
        } else {
          $(options.prevBtn).hide();
        }

        if (index == length - 1) {
          $(options.nextBtn).hide();
        } else {
          $(options.nextBtn).show();
        }
      }

      // Triggering event when go
      $.publish('go.slideshow');
    },

    // Returns the index for a given DOM element `slide`.
    // If no slide is given, returns the current index.
    index(slide) {
      var self = this;

      return slide ? self._$slides.index(slide) : self._currentIndex;
    },

    // Go to the next slide in the show.
    next() {
      var self = this;

      self.go(self._currentIndex + 1);
    },

    // Toggle the play/pause state of the slideshow via the boolean `paused` argument.
    pause() {
      var self = this;
      var paused = !self._paused;

      self._paused = paused;
      self.element.controller('option', 'paused', paused);
      self[paused ? '_pause' : '_resume']();
    },

    // Go to the previous slide in the show.
    prev() {
      var self = this;

      self.go(self._currentIndex - 1);
    },

    // Triggered at the end of the slide animation sequence.
    _afterAnimate(slide, $slideBefore) {
      const self = this;
      const options = self.options;
      const index = self.index(slide);
      const length = self._$slides.length;
      const nextIndex = (index + 1) % length;
      const nextSlide = self._$slides[nextIndex];
      const href = $(slide).find('[href]')
        .attr('href');

      $slideBefore.css('visibility', 'hidden');

      // Track slide change.
      if ((/INT_ID=(\w+)/i).test(href || '')) {
        $.publish('ScSlideShowContentShown.sitecatalystutil', RegExp.$1);
      }

      // Only setup the next slide if the user hasn't manually navigated the show
      // and there is more than one slide in the show.
      if (self._currentIndex === index && length > 1) {
        // Start preloading the next slide.
        $(nextSlide)
          .lazyloader()
          .lazyloader('load');

        // Prepare to go to the next slide after the designated delay.
        if (!self._paused) {
          self._timeout = delay($.proxy(self, 'go'), options.waitDuration, null, nextIndex);
        }
        self._trigger('afterAnimate', null, slide);
      }
    },

    _beforeAnimate(slide) {
      var self = this;
      var options = self.options;

      self._trigger('beforeAnimate', null, slide);
      self._$slides.removeClass(options.currentClass);
    },

    // Cleanup navigation and timeouts.
    _destroy() {
      var self = this;

      clearTimeout(self._timeout);
      self._$slides.each(function() {
        $(this).removeAttr('style');

        // Fast forward any animations.
        var widget = $(this).stop(true)
          .data('lazyloader');

        // We don't want to have to instance the widget just to destroy it,
        // so we need to do this check to avoid the error with referencing instance methods before instantiating.
        if (widget) {
          widget.destroy();
        }
      });

      self.element.hammer().off();
    },

    // Triggered when a preloaded image encounteres an error.
    _error(slide) {
      var self = this;

      $(slide).remove();

      // Remove the broken slide from the show.
      self._$slides = self._$slides.not(slide);

      // Update navigation element.
      self.element.controller('option', 'tracks', self._$slides.length);

      // Advance to the next slide.
      self.next();
    },

    // Triggered when there aren't any images left in the preloading queue.
    _load(slide) {
      var self = this;
      var index = self.index(slide);

      if (index === self._currentIndex) {
        self._animate(slide);
      }
    },

    // Resize the slideshow to stay above the fold.
    _resize() {
      var self = this;

      if (self.options.fullViewport) {
        self.$window || (self.$window = $(window));
        var windowWidth = self.$window.width();
        var windowHeight = self.$window.height();

        self.element.css('height', windowHeight);
        self._$slides.width(windowWidth);
        self._$container.width(windowWidth * self._$slides.length);
        self._verticalOffset ||
                    (self._verticalOffset = -(self.element.offset().top));

        if (self._verticalOffset) {
          self.element.css('margin-top', self._verticalOffset);
        }

        var controlWidth = self.element.find('.ui-controller').width();
        var horizontalOffset = -(windowWidth / 2 - controlWidth / 2 - windowWidth * 0.01);
        var verticalOffset = -((windowHeight / 2) + self._verticalOffset);

        self.element.find('.ui-controller-prev').css({
          left: horizontalOffset,
          top: verticalOffset,
        });
        self.element.find('.ui-controller-next').css({
          right: horizontalOffset,
          top: verticalOffset,
        });
      } else {
        var options = self.options.resize;
        var height = Math.min(options.maxHeight, Math.max(options.minHeight, $(window).height() - options.heightAboveFold));

        self.element.css('height', height);
      }
    },
  });

  // Add the different types of slideshows.
  $.extend($.bc.slideshow, {
    fade(self) {
      // `fade` slideshow needs to make sure all the slides have the same `z-index` (DOM default).
      self._$slides.css({ opacity: 0, visibility: 'hidden', 'z-index': 0 });
    },
    push(self) {
      width = self.element.width();
      // `push` slideshow needs to make sure each slide is the width of the slideshow element.
      // and the slideshow container can hold all of them.
      self._$slides.width(width);
      self._$container.width(width * self._$slides.length);
    },
  });

  // Fade Slideshow
  // -------------------
  $.extend($.bc.slideshow.fade.prototype, {

    // Fades in a DOM element `slide` on top of the other elements in the container.
    _animate(slide, resume) {
      const self = this;
      const options = self.options;
      const $slide = $(slide);
      const $slideBefore = self._$slides.filter(`.${options.currentClass}`).first();

      self._beforeAnimate(slide);
      if (!resume) {
        // Re-position slide so that it appears on top of the other elements in the show.
        $slide
          .css('opacity', 0)
          .appendTo(self._$container);
      }
      $slide
        .data('animated', false)
        .css('visibility', 'visible')
        .animate({ opacity: 1 }, options.animateDuration, options.easing, bind(self._afterAnimate, self, slide, $slideBefore));
      $(slide).addClass(options.currentClass);
    },

    _pause() {
      var self = this;
      var $slide = self._$slides.eq(self._currentIndex);

      // If the current slide is in the process of animating, then _freeze_ the animation.
      if ($slide.is(':animated')) {
        $slide.data('animated', true).stop();
      }
      clearTimeout(self._timeout);
    },

    _resume() {
      var self = this;
      var slide = self._$slides.get(self._currentIndex);

      // If the current slide was in the process of animating, then simply resume.
      if ($(slide).data('animated')) {
        self._animate(slide, true);
      } else {
        self.next();
      }
    },
  });

  // Push Slideshow
  // -------------------
  $.extend($.bc.slideshow.push.prototype, {

    // Pushes the new slide into view.
    _animate(slide, resume) {
      var self = this;
      var options = self.options;
      var left = $(slide)
        .css('visibility', 'visible')
        .position().left;

      self._beforeAnimate(slide);
      $(slide).addClass(options.currentClass);
      if (!resume) {
        self._$container.stop(true);
      }
      self._$container
        .data('animated', false)
        .animate({ left: -left }, options.animateDuration, options.easing, bind(self._afterAnimate, self, slide));

      self._trigger('animate');
    },

    _pause() {
      var self = this;
      var $container = self._$container;

      // If the current slide is in the process of animating, then _freeze_ the animation.
      if ($container.is(':animated')) {
        $container.data('animated', true).stop();
      }
      clearTimeout(self._timeout);
    },

    _resume() {
      var self = this;
      var slide = self._$slides.get(self._currentIndex);

      // If the current slide was in the process of animating, then simply resume.
      if (self._$container.data('animated')) {
        self._animate(slide, true);
      } else {
        self.next();
      }
    },
  });
});
