/* istanbul ignore next */
const dynamicComponentsMap = {
  'events-list': {
    loader: () => import(/* webpackChunkName: "events.component" */'common/components/organisms/events'),
  },
  'featured-article': {
    loader: () => import(/* webpackChunkName: "featured-article.component" */'common/components/featured-article'),
  },
  'featured-gearhead': {
    loader: () => import(/* webpackChunkName: "featured-gearhead.component" */'common/components/featured-gearhead'),
    code: 'GHX',
  },
  'featured-product': {
    loader: () => import(/* webpackChunkName: "featured-product.component" */'common/components/featured-product'),
    code: 'FP',
  },
  'image-board': {
    loader: () => import(/* webpackChunkName: "image-board.component" */'common/components/image-board'),
    code: 'IG',
  },
  'image-grid': {
    loader: () => import(/* webpackChunkName: "image-grid.component" */'common/components/image-grid'),
  },
  'navigation-menu': {
    loader: () => import(/* webpackChunkName: "navigation-menu.component" */'common/components/tabs-navigation/navigation-menu'),
    code: 'NM',
  },
  'brand-logos': {
    loader: () => import(/* webpackChunkName: "brand-logos.component" */'common/components/brand-logos'),
  },
  'other-products': {
    loader: () => import(/* webpackChunkName: "other-products.component" */'common/components/other-products'),
    code: 'OP',
  },
  'product-row': {
    loader: () => import(/* webpackChunkName: "product-listing-row.component" */'common/components/room-slider/product-listing-row'),
    code: 'ROOMSLIDER',
  },
  'room-slider': {
    loader: () => import(/* webpackChunkName: "room-slider.component" */'common/components/room-slider'),
    code: 'ROOMSLIDER',
  },
  'social-video': {
    loader: () => import(/* webpackChunkName: "social-video.component" */'common/components/social-video'),
    code: 'SV',
  },
  'story-board': {
    loader: () => import(/* webpackChunkName: "story-board.component" */'common/components/story-board'),
    code: 'SBR',
  },
  'story-image': {
    loader: () => import(/* webpackChunkName: "story-image.component" */'common/components/story-image'),
  },
  'tabs-navigation': {
    // TODO figure out why this rule wasn't failing before? and a long term solution
    // eslint-disable-next-line import/no-cycle
    loader: () => import(/* webpackChunkName: "tabs-navigation.component" */'common/components/tabs-navigation'),
    code: 'TAB',
  },
  'top-ten': {
    loader: () => import(/* webpackChunkName: "top-ten.component" */'common/components/top-ten'),
    code: 'T10',
  },
  'video-story': {
    loader: () => import(/* webpackChunkName: "video-story.component" */'common/components/social-video'),
    code: 'VSTORY',
    props: { isStory: true },
  },
  'visual-product': {
    loader: () => import(/* webpackChunkName: "visual-product.component" */'common/components/visual-product'),
    code: 'VP',
  },
  body: {
    loader: () => import(/* webpackChunkName: "freeform.component" */'common/components/freeform'),
    code: 'Freeform',
  },
  explore: {
    loader: () => import(/* webpackChunkName: "content.list.component" */'common/components/content/list'),
    code: 'EXP',
  },
  freestyle: {
    loader: () => import(/* webpackChunkName: "freestyle.component" */'common/components/freestyle'),
  },
  gps: {
    loader: () => import(/* webpackChunkName: "guided-product-selection.component" */'common/components/guided-product-selection'),
    code: 'GPS',
  },
  recommendations: {
    loader: () => import(/* webpackChunkName: "recommendations.component" */'common/components/recommendations'),
    code: 'REC',
  },
  rotator: {
    loader: () => import(/* webpackChunkName: "rotator.component" */'common/components/promotion/plp-rotator-container'),
    code: 'PR',
  },
  stl: {
    loader: () => import(/* webpackChunkName: "shop-the-look.component" */'common/components/shop-the-look'),
    code: 'STL',
  },
  'story-text-image': {
    loader: () => import(/* webpackChunkName: "story-text-image.component" */'common/components/story-text-image'),
    code: 'STI',
  },
  'product-promo-image': {
    loader: () => import(/* webpackChunkName: "product-promo-image.component" */'common/components/product-promo-image'),
    code: 'PPI',
  },
  'product-listing': {
    loader: () => import(/* webpackChunkName: "product-listing.component" */'common/components/product-list'),
    code: 'PL',
  },
  loyalty: {
    loader: () => import(/* webpackChunkName: "loyalty.component" */'common/components/loyalty'),
  },
  'curalate-fanreel': {
    loader: () => import(/* webpackChunkName: "curalate-fanreel.component" */'common/components/organisms/curalate/fanreel'),
  },
  'complete-the-look': {
    loader: () => import(/* webpackChunkName: "complete-the-look.component" */'common/components/complete-the-look'),
    code: 'CTL',
  },
  'story-item': {
    loader: () => import(/* webpackChunkName: "content.card.component" */'common/components/content/card'),
  },
  'product-comparison-chart': {
    loader: () => import(/* webpackChunkName: "comparison.chart.component" */'common/components/comparison-chart'),
  },
  'info-columns': {
    loader: () => import(/* webpackChunkName: "info.columns.component" */'common/components/info-columns'),
  },
  'questions-and-answers': {
    loader: () => import(/* webpackChunkName: "questions.and.answers.component" */'common/components/questions-and-answers'),
  },
  'suscription-box': {
    loader: () => import(/* webpackChunkName: "suscription-box.component" */'common/components/suscription-box'),
  },
};

export default dynamicComponentsMap;
