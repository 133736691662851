import { validate } from 'common/util/bc.validation.helper';
import { clearFormErrors, setErrorMessageForInput } from 'common/util/accessibility-helper';
import { find } from 'underscore';

// Do a global config with input and message classes
// methods use those globals unless provided
const options = {
  errorClassForMessages: 'alert-message-error',
  errorClassForInputs: 'alert-input-error',
};

const swearWords = [
  'cunt',
  'pussy',
  'cock',
  'motherfucker',
  'cocksucker',
  'fag',
  'faggot',
  'dyke',
  'bastard',
  'fuck',
  'f u c k',
  'nigga',
  'whore',
];

// Removes all error messages, classes and validation attributes
// Params: optional errorClass to be removed, otherwise uses the default errorClassForMessages
//         optional formSelector
function clearFormValidations(errorClass, formSelector) {
  const { errorClassForMessages, errorClassForInputs } = options;
  const errorMessages = errorClass && document.querySelectorAll(`.${errorClass}`) || document.querySelectorAll(`.${errorClassForMessages}`);
  const inputErrors = document.querySelectorAll(`.${errorClassForInputs}`);
  let errorClassesToRemove;

  if (errorClass) {
    errorClassesToRemove = Array.isArray(errorClass) ? errorClass : [errorClass];
  } else {
    errorClassesToRemove = [errorClassForMessages];
  }

  if (errorMessages.length > 0) {
    Array.from(errorMessages).forEach((message) => {
      message.innerText = '';
      message.classList.remove(...errorClassesToRemove);
    });
  }

  if (inputErrors.length > 0) {
    Array.from(inputErrors).forEach((input) => {
      input.classList.remove(errorClassForInputs);
    });
  }

  clearFormErrors(formSelector);
}

// Moves focus to the first error field
function focusFirstErrorField(errorClass) {
  const inputErrors = document.querySelectorAll(`.${errorClass || options.errorClassForInputs}`);

  if (inputErrors.length === 0) return;

  inputErrors[0].focus();
}

// Shows a message in a specified target node or creates a node and inserts it after an input element
// type: 'message' or 'error'
// opts: {
//  messageTargetId, // If there is already an element that will receive the error, pass a target ID and that will be used
//  messageClass, // If you have class or classes that you want to use instead of the default. This is an array of class names (string).
//  inputId // The ID of the element associated to the message you want to show
// }
function showMessage(type, message, opts) {
  const messageClass = opts.messageClass;
  const messageTargetId = opts.messageTargetId;

  const input = opts.inputId && document.querySelector(`#${opts.inputId}`);
  let messageElement = messageTargetId && document.querySelector(`#${messageTargetId}`);
  let targetId;

  if (!input && !messageElement) return;

  if (!messageElement) {
    // Check if message element was already created for this input
    targetId = `${opts.inputId}-messages`;
    const targetElement = document.querySelector(`#${targetId}`);

    if (!targetElement) {
      messageElement = document.createElement('p');
      messageElement.id = targetId;
      input.insertAdjacentElement('afterend', messageElement);
    } else {
      messageElement = targetElement;
    }
  }

  // Add class to message
  if (messageClass) {
    messageElement.classList.add(...(Array.isArray(messageClass) ? messageClass : [messageClass]));
  } else if (type === 'error') {
    messageElement.classList.add(options.errorClassForMessages);
  }

  // Add class to input
  if (type === 'error' && input) {
    input.classList.add(options.errorClassForInputs);
  }

  messageElement.innerText = message;

  if (type === 'error' && opts.inputId) {
    setErrorMessageForInput(`#${opts.inputId}`, messageTargetId || targetId);
  }
}

// Receives an array of validations and returns the invalid ones
// formTests: [{ value: '', validations: [{ test: 'email' }, { test: 'required' }] }]
// opts: { country }
function validateForm(formTests, opts = {}) {
  let failedTests;

  const errors = formTests.filter((formUnit) => {
    failedTests = formUnit.validations.filter((validation) => {
      const fieldValue = formUnit.value || '';

      return validate([{ test: validation.test }], fieldValue, opts.country);
    });

    formUnit.errors = failedTests;

    return failedTests.length;
  });

  return errors;
}

// Receives the text to be tested for explicit content
function isContentExplicit(text = '') {
  const words = text.replace(/[^a-zA-Z ]/g, '').split(' ').map(word => word.toLowerCase());

  return !!find(swearWords, word => words.includes(word));
}

export {
  clearFormValidations,
  focusFirstErrorField,
  showMessage,
  validateForm,
  isContentExplicit,
};
