import React, { Suspense } from 'react';

const fallbackStyle = {
  margin: '0 20px 50px',
  height: '400px',
  width: '800px',
};

// TODO use GenericErrorBoundary instead
class ComponentLoaderErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch = (error, info) => {
    $.error(`[ComponentLoader] Failed to dynamically load component with: ${error} ${info.componentStack}]`);
  };

  render() {
    if (this.state.hasError) {
      return this.props.errorMessage || null;
    }

    return this.props.children;
  }
}

function Placeholder() {
  return (
    <div className="placeholder" style={fallbackStyle} />
  );
}

function ComponentLoader({ children, placeholder = <Placeholder />, errorMessage }) {
  return (
    <ComponentLoaderErrorBoundary errorMessage={errorMessage}>
      <Suspense fallback={placeholder}>
        {children}
      </Suspense>
    </ComponentLoaderErrorBoundary>
  );
}

export { ComponentLoaderErrorBoundary };

export default ComponentLoader;
