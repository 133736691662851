import React from 'react';
import PropTypes from 'prop-types';
import styles from 'commonStyles/components/layout/_component-group';

const ComponentGroup = ({ children }) => (
  <div className={styles.wrap}>
    {children}
  </div>
);

ComponentGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.element
    ),
  ]).isRequired,
};

export default ComponentGroup;
