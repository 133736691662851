// Controller
// ===================
// Re-usable controller module that can be added to any widget.
// Supports prev/next, play/pause and numbered track controls.
// Will trigger `click`, `mouseenter` and `mouseleave` events, which can be bound to on `this.element`
// or via callbacks in the options.
// Setting options for `index`, `paused` and `tracks` will update the UI.

import { translate } from 'common/localization';

$.widget('bc.controller', {
  options: {
    activeClass: 'active',
    btnResetClass: 'btn-reset',
    controllerBtnClass: 'ui-controller__btn',
    controllerClass: 'ui-controller',
    controlIndex: false,
    index: 0,
    nextBtnClass: 'ui-controller-next__btn',
    nextClass: 'ui-controller-next',
    nextControl: true,
    pauseBtnClass: 'ui-controller-pause__btn',
    pauseClass: 'ui-controller-pause',
    pauseControl: false,
    paused: false,
    pausedClass: 'paused',
    prevBtnClass: 'ui-controller-prev__btn',
    prevClass: 'ui-controller-prev',
    prevControl: true,
    imageTitle: false,
    imageTitles: [],
    imageMedium: false,
    imagesMedium: [],
    imageLarge: false,
    imagesLarge: [],
    showThumbnails: false,
    thumbnails: [],
    tracks: 0,
    trackBtnClass: 'ui-controller-track__btn',
    trackClass: 'ui-controller-track',
    trackControl: true,
  },

  // Create control elements.
  _create() {
    const self = this;
    const options = self.options;

    self._$controller = $('<div />').addClass(options.controllerClass);
    self._$controllerList = $('<ul />').appendTo(self._$controller);
    if (options.prevControl) {
      self._createControl('prev');
    }
    if (options.pauseControl) {
      self._createControl('pause');
    }
    if (options.trackControl) {
      if (options.tracks > 0 && options.tracks != Infinity) {
        for (var i = 0; i < options.tracks; i++) {
          self._createControl('track', i);
        }
      }
    }
    if (options.nextControl) {
      self._createControl('next');
    }

    // Don't show controller if there aren't multiple tracks to navigate between.
    if (options.tracks > 1) {
      self._$controller.appendTo(self.element);
    }
  },

  // Creates a control item, inserts into the controller element and binds up events.
  _createControl(name, i) {
    const self = this;
    const options = self.options;
    const fn = `_${name}`;
    const $text = $('<span />')
      .addClass('visually-hidden')
      .text(translate(`home.carousel.${name}.slide`, i + 1, options.tracks));
    let html;
    let wrapper = '<li />';

    if (name == 'track') {
      html = options.showThumbnails && '<img src="' + options.thumbnails[i] + '" />';
      wrapper = '<li ';

      if (options.controlIndex) {
        wrapper += 'data-img-index = "' + i + '"';
      }
      if (options.imageTitle && i < options.imageTitles.length) {
        wrapper += 'data-img-title = "' + options.imageTitles[i] + '"';
      }
      if (options.imageMedium && i < options.imagesMedium.length) {
        wrapper += 'data-medium-img = "' + options.imagesMedium[i] + '"';
      }
      if (options.imageLarge && i < options.imagesLarge.length) {
        wrapper += 'data-large-img = "' + options.imagesLarge[i] + '"';
      }
      wrapper += '/>';
    }

    const $item = $('<button />')
      .attr('type', 'button')
      .html(html)
      .wrap(wrapper)
      .addClass(options.btnResetClass)
      .addClass(options.controllerBtnClass)
      .addClass(options[name + 'BtnClass'])
      .data('index', i)
      .prepend($text);

    self._on($item, { click: fn, mouseenter: fn, mouseleave: fn });

    $item.parent()
      .addClass(options[name + 'Class'])
      .appendTo(self._$controllerList);
  },

  _destroy() {
    const self = this;

    self._$controller.remove();
  },

  // Handler for `next` control interaction.
  _next(event) {
    const self = this;

    self._trigger(event.type + 'next', event);
  },

  // Handler for `pause` control interaction.
  _pause(event) {
    const self = this;

    self._trigger(event.type + 'pause', event);
  },

  // Handler for `prev` control interaction.
  _prev(event) {
    const self = this;

    self._trigger(event.type + 'prev', event);
  },

  _setOption(key, value) {
    const self = this;
    const options = self.options;

    switch (key) {
      case 'paused':
        self._$controller
          .find('.' + options.pauseClass)
          .toggleClass(options.pausedClass, value);
        break;

      case 'index':
        self._$controller
          .find('.' + options.trackClass)
          .removeClass(options.activeClass)
          .eq(value)
          .addClass(options.activeClass);

        self._$controller
          .find('.' + options.trackBtnClass)
          .attr('aria-current', false)
          .eq(value)
          .attr('aria-current', true);
        break;

      case 'tracks':
      // If the current index is greater than number of tracks, reset the index.
        if (options.index >= value) {
          self._setOption('index', value - 1);
        }

        // If the new value is greater than current, add additional tracks to the controller element.
        if (value > options.tracks) {
          let i = options.tracks;

          while (i++ < value) {
            self._createControl('track', i);
          }
          if (value > 1) {
            self._$controller.appendTo(self.element);
          }
        } else if (value < options.tracks) {
        // If the new value is less than current, remove track elements from the controller element.
        // If there are now fewer than two tracks, remove control element.
          if (value < 2) {
            self._$controller.detach();
          }
          self._$controller.find('.' + options.trackClass + ':gt(' + (value - 1) + ')').remove();
        }
        break;
    }

    return self._superApply(arguments);
  },

  // Handler for `track` control interaction.
  _track(event) {
    const self = this;
    const index = $(event.currentTarget).data('index');

    // Need to wrap the argument in an array to avoid a condition with a falsey check in the `._trigger()` method.
    self._trigger(event.type + 'track', event, [index]);
  },
});
