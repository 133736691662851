import React from 'react';
import PropTypes from 'prop-types';
import ComponentLoader from 'common/components/component-loader';
import ComponentGroup from 'common/components/layout/component-group';
import dynamicComponentsMap from 'common/util/dynamic-components-map';
import { TrackingProvider } from 'common/components/tracking-context';

class Dynamic extends React.Component {
  static propTypes = {
    componentGroupData: PropTypes.shape({
      components: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        options: PropTypes.object,
      })).isRequired,
    }),
    wrapperClass: PropTypes.string,
  };

  static defaultProps = {
    wrapperClass: '',
  };

  constructor() {
    super();
    this.componentSTLPositionRef = React.createRef();
    this.componentSTLPositionRef.current = 1;
  }

  componentDidMount() {
    BC.publish('component.group.ready', this.props.componentGroupData);
  }

  render() {
    const { componentGroupData, wrapperClass } = this.props;
    const { campaignId } = componentGroupData;

    return (
      <ComponentGroup className={wrapperClass}>
        {
          componentGroupData.components.map(({ options, type, ...opts }, position) => {
            if (!dynamicComponentsMap[type]) {
              $.error(`[ComponentGroup] Error on component data, provided type: [${type}] not found on component mapping`);

              return null;
            }

            const { loader, code, props = {} } = dynamicComponentsMap[type];
            const Component = React.lazy(loader);

            const componentProps = {
              ...options,
              ...props,
              ...opts,
            };

            if (type === 'stl') {
              componentProps.position = this.componentSTLPositionRef.current;
              this.componentSTLPositionRef.current = this.componentSTLPositionRef.current + 1;
            }

            return (
              <ComponentLoader key={`${type}-${position}`}>
                <TrackingProvider value={{
                  campaignId,
                  code,
                  pageSection: position.toString(),
                  componentName: type,
                }}
                >
                  <Component {...componentProps} />
                </TrackingProvider>
              </ComponentLoader>
            );
          })
        }
      </ComponentGroup>
    );
  }
}

export default Dynamic;
